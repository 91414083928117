var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-layout-content",
        [
          _c(
            "a-row",
            [
              _c(
                "a-col",
                { attrs: { span: 24 } },
                [
                  _c(
                    "a-card",
                    { attrs: { title: "Asset Inquiry > Adjustment" } },
                    [
                      _c(
                        "a-tabs",
                        {
                          attrs: {
                            "default-active-key": _vm.currentTab,
                            activeKey: _vm.currentTab
                          },
                          on: { change: _vm.changeMenu }
                        },
                        [
                          _c(
                            "a-tab-pane",
                            { key: "Financial", attrs: { tab: "Financial" } },
                            [_c("AdjustmentFinancial")],
                            1
                          ),
                          _c(
                            "a-tab-pane",
                            { key: "Assignment", attrs: { tab: "Assignment" } },
                            [_c("AdjustmentAssignment")],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }