

























import Vue from "vue";
export default Vue.extend({
  data() {
    return {
      currentTab: "Financial" as string,
    };
  },
  methods: {
    changeMenu(currentTabMenu) {
      this.currentTab = currentTabMenu;
    },
  },
});
