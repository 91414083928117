








































































































































































































































































































import { Vue, Component, Watch } from "vue-property-decorator";
import { Messages } from "@/models/enums/messages.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constants/date.constant";
import { ColumnTableCustom } from "@/models/interface/util.interfase";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { contactServices } from "@/services/contact.service";
import { purchaseRequisitionServices } from "@/services/purchaseRequisition.service";
import { productService } from "@/services/product.service";
import moment from "moment";
import {
  CreatePurchaseRequisitionIn,
  PrPoLine,
} from "@/models/interface/purchaseRequisition.interface";
import {
  changeCurrencytoNumeric,
  currencyFormat,
} from "@/validator/globalvalidator";
import { assetsServices } from "@/services/assets.service";
import { debounce } from "@/helpers/debounce";

interface DataListItems {
  productId: string;
  productCode: string;
  productName: string;
  uom: string;
  qty: string;
  description: string;
  id?: any;
  key: number;
  no: string;
  unitCode: string;
  unitId: string;
}

interface Dropdown {
  key?: number;
  name?: string;
  id?: string;
  available?: number;
}

enum REQUEST_TYPE {
  RENT = "Rent to Rent",
  SERVICE = "Service",
  UNIT = "Unit",
  SPAREPART = "Sparepart",
  OTHERS = "Others",
  CAR = "Car",
}

enum STATUS {
  NEED_APPROVAL = "Need Approval",
  DRAFT = "Draft",
  APPROVED = "Approved",
  REJECTED = "Rejected",
  CANCELLED = "Cancelled",
  RELEASED_PO = "Released Po",
}
@Component
export default class CreatePurchaseRequisition extends Vue {
  DEFAULT_DATE_FORMAT = DEFAULT_DATE_FORMAT;
  form = this.$form.createForm(this, { name: "createPurchaseRequisition" });
  page = 0 as number;
  limit = 10 as number;
  dataListItems = [] as DataListItems[];
  selectedRowKeys = [] as number[];
  listDeletedId = [] as string[];
  mode = "" as string;
  dataBranch = [] as any[];
  dataRequester = [] as any[];
  dataSupplier = [] as any[];
  dataRequestType = [] as any[];
  checkMandatory = 0 as number;
  statusData = "init" as string;
  loading = {
    branch: false as boolean,
    requester: false as boolean,
    supplierName: false as boolean,
    requestType: false as boolean,
    reject: false as boolean,
    approve: false as boolean,
    cancel: false as boolean,
    submit: false as boolean,
    saveAsDraft: false as boolean,
    update: false as boolean,
    table: false as boolean,
  };
  show = {
    purchaseRequisitionNumber: false as boolean,
  };
  disabledForm = {
    purchaseRequisitionNumber: true as boolean,
    branch: false as boolean,
    requester: false as boolean,
    date: false as boolean,
    supplierName: false as boolean,
    requestType: false as boolean,
    description: false as boolean,
  };
  disabledButton = {
    addRow: true as boolean,
    deleteRow: true as boolean,
    reject: true as boolean,
    approve: true as boolean,
    cancel: true as boolean,
    submit: true as boolean,
    saveAsDraft: true as boolean,
    update: true as boolean,
  };
  formRules = {
    purchaseRequisitionNumber: {
      decorator: ["purchaseRequisitionNumber"],
    },
    branch: {
      decorator: [
        "branch",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    requester: {
      decorator: [
        "requester",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    date: {
      decorator: [
        "date",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    supplierName: {
      decorator: ["supplierName"],
    },
    requestType: {
      decorator: [
        "requestType",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    description: {
      decorator: [
        "description",
        {
          rules: [
            {
              required: true,
              message: this.$t(Messages.VALIDATION_REQUIRED_ERROR),
            },
          ],
        },
      ],
    },
    status: {
      decorator: ["status"],
    },
  };
  columnsTable = [
    {
      title: this.$t("lbl_number_short"),
      dataIndex: "no",
      key: "no",
      width: 100,
      scopedSlots: { customRender: "isNull" },
      responsiveColSelect: [
        {
          name: "productCode",
          placeholder: this.$t("lbl_part_number_placeholder"),
          style: "width: 100%;",
          disabled: "",
          value: "id",
          options: [] as any[],
        },
        {
          name: "productName",
          placeholder: this.$t("lbl_part_name_placeholder"),
          style: "width: 100%;",
          disabled: "",
          value: "id",
          options: [] as any[],
        },
        {
          name: "unitCode",
          placeholder: this.$t("lbl_unit_code_placeholder"),
          style: "width: 100%;",
          disabled: "",
          value: "id",
          options: [] as any[],
        },
      ],
      responsiveColInput: [
        {
          name: "uom",
          placeholder: this.$t("lbl_uom_placeholder"),
          style: "width: 100%;",
          disabled: true,
        },
        {
          name: "qty",
          placeholder: this.$t("lbl_qty_placeholder"),
          style: "width: 100%;",
          disabled: "",
        },
        // {
        //   name: "description",
        //   placeholder: this.$t("lbl_description_placeholder"),
        //   style: "width: 100%;",
        //   disabled: "",
        // },
      ],
      responsiveColTextArea: [
        {
          name: "description",
          placeholder: "Description",
          style: "width: 100%",
          disabled: false,
        },
      ],
    },
    {
      title: this.$t("lbl_part_number"),
      dataIndex: "productCode",
      key: "productCode",
      width: 300,
      scopedSlots: { customRender: "productCode" },
    },
    {
      title: this.$t("lbl_part_name"),
      dataIndex: "productName",
      key: "productName",
      width: 300,
      scopedSlots: { customRender: "productName" },
    },
    {
      title: this.$t("lbl_uom"),
      dataIndex: "uom",
      key: "uom",
      width: 200,
      scopedSlots: { customRender: "uom" },
    },
    {
      title: this.$t("lbl_qty"),
      dataIndex: "qty",
      key: "qty",
      width: 200,
      scopedSlots: { customRender: "qty" },
    },
    {
      title: this.$t("lbl_description"),
      dataIndex: "description",
      key: "description",
      width: 200,
      scopedSlots: { customRender: "description" },
    },
  ] as ColumnTableCustom[];
  columnsTableModal = [
    {
      title: this.$t("lbl_serial_number"),
      dataIndex: "serialNumber",
      key: "serialNumber",
      width: 150,
      scopedSlots: { customRender: "serialNumber" },
      responsiveColInput: [
        {
          name: "serialNumber",
          placeholder: "Serial Number",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "type",
          placeholder: "Type",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "specification",
          placeholder: "Specification",
          style: "width: 100%;",
          disabled: "",
        },
        {
          name: "capacity",
          placeholder: "Capacity",
          style: "width: 100%;",
          disabled: "",
        },
      ],
    },
    {
      title: "Type",
      dataIndex: "type",
      key: "type",
      width: 150,
      scopedSlots: { customRender: "type" },
    },
    {
      title: "Specification",
      dataIndex: "specification",
      key: "specification",
      width: 150,
      scopedSlots: { customRender: "specification" },
    },
    {
      title: "Capacity",
      dataIndex: "capacity",
      key: "capacity",
      width: 150,
      scopedSlots: { customRender: "capacity" },
    },
  ] as ColumnTableCustom[];

  get formItemLayout() {
    return {
      labelCol: { span: 8 },
      wrapperCol: { span: 14 },
    };
  }

  @Watch("checkMandatory")
  public changeCheckMandatory(value) {
    if (value > 0) {
      debounce(() => {
        if (
          this.form.getFieldValue("branch") &&
          this.form.getFieldValue("requester") &&
          this.form.getFieldValue("date") &&
          this.form.getFieldValue("description")
        ) {
          this.disabledButton.addRow = false;
          this.disabledButton.deleteRow = false;
          if (this.mode == "create") {
            this.disabledButton.submit = false;
            this.disabledButton.saveAsDraft = false;
          } else {
            switch (this.form.getFieldValue("status")) {
              case STATUS.NEED_APPROVAL:
                this.disabledButton.update = false;
                this.disabledButton.reject = false;
                this.disabledButton.approve = false;
                this.disabledButton.cancel = false;
                break;
              case STATUS.DRAFT:
                this.disabledButton.submit = false;
                this.disabledButton.update = false;
                this.disabledButton.cancel = false;
                break;
              default:
                break;
            }
          }
        }
      }, 500);
    }
  }
  created() {
    this.mode = this.$route.meta.mode;
  }
  mounted() {
    this.getBranch("");
    this.getRequester("");
    this.getSupplier("");
    this.getRequestType();
    if (this.mode != "create") this.getDetailData();
  }
  getDetailData() {
    purchaseRequisitionServices
      .detailPurchaseRequisition(this.$route.params.id)
      .then(res => {
        this.checkDropdown(res);
        if (res.status != STATUS.DRAFT) {
          this.show.purchaseRequisitionNumber = true;
        }
        if (res.status == STATUS.DRAFT) {
          for (const key in this.disabledForm) {
            if (key != "requestType") this.disabledForm[key] = false;
            else this.disabledForm[key] = true;
          }
          for (const key in this.disabledButton) {
            if (key != "saveAsDraft" && key != "reject" && key != "approve")
              this.disabledButton[key] = false;
          }
        } else if (res.status == STATUS.NEED_APPROVAL) {
          for (const key in this.disabledForm) {
            if (key != "requestType") this.disabledForm[key] = false;
            else this.disabledForm[key] = true;
          }
          for (const key in this.disabledButton) {
            if (key != "saveAsDraft" && key != "submit")
              this.disabledButton[key] = false;
          }
        } else {
          for (const key in this.disabledForm) {
            this.disabledForm[key] = true;
          }
          for (const key in this.disabledButton) {
            this.disabledButton[key] = true;
          }
        }
        this.getProductCode("", res.requestType, false);
        this.getProductName("", res.requestType, false);
        debounce(() => {
          this.form.setFieldsValue({
            branch: res.branchId,
            requester: res.requesterId,
            date: moment(res.date).format(),
            purchaseRequisitionNumber: res.documentNo,
            supplierName: { key: res.supplierId, label: res.supplierName },
            requestType: res.requestType,
            description: res.description,
            status: res.status,
          });
          this.getUnitCode("");
        }, 500);
        this.appendOrdeleteColumnTable(res.requestType);

        let dataObj = {} as any;
        res.detailLineDTOS?.forEach((item, index) => {
          dataObj = {
            no: index + 1 + ".",
            productId: item.productId,
            productCode: item.productCode,
            productName: item.productName,
            uom: item.uom,
            qty: item.qty ? currencyFormat(item.qty) : "",
            description: item.description,
            key: index,
            id: item.id,
          };
          if (
            res.requestType === REQUEST_TYPE.SERVICE ||
            res.requestType === REQUEST_TYPE.OTHERS
          ) {
            (dataObj.unitCode = item.unitCode), (dataObj.unitId = item.unitId);
          }
          if (
            res.status != STATUS.NEED_APPROVAL &&
            res.status != STATUS.DRAFT
          ) {
            dataObj.disableByRow = [
              "productCode",
              "productName",
              "uom",
              "qty",
              "description",
            ];
          } else {
            dataObj.disableByRow = [];
          }
          this.dataListItems.push(dataObj);
        });
      });
  }
  changeDropDown(value, type) {
    if (value) {
      switch (type) {
        case "branch":
          debounce(() => {
            this.getUnitCode("");
          }, 500);
          break;
        case "requestType":
          this.getInitialProductCodeAndName(value);
          this.appendOrdeleteColumnTable(value);
          this.dataListItems = [];
          break;
        default:
          break;
      }
    }
  }
  appendOrdeleteColumnTable(value) {
    if (value === REQUEST_TYPE.SERVICE || value === REQUEST_TYPE.OTHERS) {
      if (
        this.columnsTable.findIndex(item => item.dataIndex === "unitCode") == -1
      )
        this.columnsTable.splice(3, 0, {
          title: "Unit Code",
          dataIndex: "unitCode",
          key: "unitCode",
          width: 200,
          scopedSlots: { customRender: "unitCode" },
        });
    } else {
      if (
        this.columnsTable.findIndex(item => item.dataIndex === "unitCode") != -1
      )
        this.columnsTable.splice(
          this.columnsTable.findIndex(item => item.dataIndex === "unitCode"),
          1
        );
    }
  }
  getUnitCode(value) {
    const params = {
      limit: 10,
      page: 0,
      search: `status!RETIRED_AND_assetLocation.warehouse.branchWarehouse.secureId~${this.form.getFieldValue(
        "branch"
      )}`,
    } as RequestQueryParamsModel;
    const listDropdown = [] as Dropdown[];
    const findColumn = this.columnsTable.find(
      column => column.responsiveColSelect
    )?.responsiveColSelect;
    if (value) params.search += `_AND_unitCode~*${value}*`;
    assetsServices.listMasterAsset(params).then(res => {
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["name"] = item.unitCode;
        listDropdown.push(item);
      });
      findColumn[2].options = listDropdown;
    });
  }
  checkDropdown(value) {
    if (this.dataBranch.findIndex(item => item.id === value.branchId) == -1) {
      this.dataBranch.push({
        id: value.branchId,
        name: value.branchName,
      });
    }
    if (
      this.dataRequester.findIndex(item => item.id === value.requesterId) == -1
    ) {
      this.dataRequester.push({
        id: value.requesterId,
        firstName: value.requesterName,
      });
    }
  }
  getRequestType() {
    purchaseRequisitionServices
      .listRequestTypePurchaseRequisition()
      .then(res => {
        this.dataRequestType = res;
      });
  }
  onCheckMandatory() {
    this.checkMandatory += 1;
  }

  getBranch(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    if (value)
      params.search = `name~*${value}*_OR_code~*${value}*_OR_address~*${value}`;
    this.loading.branch = true;
    return logisticServices
      .listWarehouseBranch(params, "")
      .then(response => {
        this.dataBranch = response.data;
      })
      .finally(() => (this.loading.branch = false));
  }
  getRequester(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `employee~true_AND_active~true`,
    };
    if (value)
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    this.loading.requester = true;
    return contactServices
      .listContactData(params)
      .then(response => {
        this.dataRequester = response.data;
      })
      .finally(() => (this.loading.requester = false));
  }
  getSupplier(value) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
      search: `supplier~true_AND_active~true`,
    };
    if (value)
      params.search += `_AND_firstName~*${value}*_OR_lastName~*${value}*`;
    this.loading.supplierName = true;
    return contactServices
      .listContactData(params)
      .then(response => {
        this.dataSupplier = response.data;
      })
      .finally(() => (this.loading.supplierName = false));
  }
  onClickButton(type) {
    this.form.validateFields((err, value) => {
      if (!err) {
        const dataLine = [] as PrPoLine[];
        let obj = {} as PrPoLine;
        this.dataListItems.forEach(item => {
          obj = {
            id: item.id,
            productId: item.productId,
            uomUnit: item.uom,
            qty: item.qty ? changeCurrencytoNumeric(item.qty) : 0,
            description: item.description,
          };
          if (
            value.requestType === REQUEST_TYPE.SERVICE ||
            value.requestType === REQUEST_TYPE.OTHERS
          ) {
            obj.unitId = item.unitId;
          }
          dataLine.push(obj);
        });
        const payload = {
          branchId: value.branch,
          requesterId: value.requester,
          date: moment(value.date).format("yyyy-MM-DD"),
          supplierId: value.supplierName?.key ?? "",
          requestType: value.requestType,
          description: value.description,
          prLineIdDelete: this.listDeletedId,
          purchaseRequistionListDTOS: dataLine,
        } as CreatePurchaseRequisitionIn;
        switch (type) {
          case "reject":
            payload.status = STATUS.REJECTED.toUpperCase();
            purchaseRequisitionServices
              .approverPurchaseRequisition(payload, this.$route.params.id)
              .then(item => {
                this.listDeletedId = [];
                this.$router.push("/purchasing/purchaserequisition");
                this.$notification.success({
                  description: `Your PR number  ${item["documentNo"]} has been rejected `,
                  message: "Success",
                  duration: 30,
                });
              });
            break;
          case "approve":
            payload.status = STATUS.APPROVED.toUpperCase();
            purchaseRequisitionServices
              .approverPurchaseRequisition(payload, this.$route.params.id)
              .then(item => {
                this.listDeletedId = [];
                this.$router.push("/purchasing/purchaserequisition");
                this.$notification.success({
                  description: `Your PR number  ${item["documentNo"]} has been approved `,
                  message: "Success",
                  duration: 30,
                });
              });
            break;
          case "cancel":
            payload.status = STATUS.CANCELLED.toUpperCase();
            purchaseRequisitionServices
              .approverPurchaseRequisition(payload, this.$route.params.id)
              .then(() => {
                this.listDeletedId = [];
                this.$router.push("/purchasing/purchaserequisition");
              });
            break;
          case "submit":
            payload.status = STATUS.NEED_APPROVAL.toUpperCase().replace(
              " ",
              "_"
            );
            if (!this.$route.params.id) {
              purchaseRequisitionServices
                .createPurchaseRequisition(payload)
                .then(item => {
                  this.listDeletedId = [];
                  this.$router.push("/purchasing/purchaserequisition");
                  this.$notification.success({
                    description: `Purchase Requisition has been create with number :  ${item["documentNumber"]}`,
                    message: "Success",
                    duration: 30,
                  });
                });
            } else {
              purchaseRequisitionServices
                .updatePurchaseRequisition(payload, this.$route.params.id)
                .then(item => {
                  this.listDeletedId = [];
                  this.$router.push("/purchasing/purchaserequisition");
                  this.$notification.success({
                    description: item["documentNo"],
                    message: "Success",
                    duration: 30,
                  });
                });
            }

            break;
          case "saveAsDraft":
            payload.status = STATUS.DRAFT.toUpperCase();
            purchaseRequisitionServices
              .createPurchaseRequisition(payload)
              .then(() => {
                this.listDeletedId = [];
                this.$router.push("/purchasing/purchaserequisition");
              });
            break;
          case "update":
            payload.status = value.status
              .toString()
              .toUpperCase()
              .replace(" ", "_");
            purchaseRequisitionServices
              .updatePurchaseRequisition(payload, this.$route.params.id)
              .then(() => {
                this.listDeletedId = [];
                this.$router.push("/purchasing/purchaserequisition");
              });
            break;
          default:
            break;
        }
      }
    });
  }
  handleInput(value, key, objectColInput, _objectColInputName, _onEvt) {
    this.dataListItems[key][objectColInput.name] = value;
    this.dataListItems = this.dataListItems.slice();
  }
  handleSearchSelectTable(value, _key, col) {
    const type = this.form.getFieldValue("requestType");
    switch (col) {
      case "productCode":
        this.getProductCode(value, type, true);
        break;
      case "productName":
        this.getProductName(value, type, true);
        break;
      case "unitCode":
        this.getUnitCode(value);
        break;
      default:
        break;
    }
  }
  handleSelectTable(value, key, col, recordOptions) {
    this.dataListItems[key] = { ...this.dataListItems[key], [col]: value };
    const options = recordOptions.find(item => item.id == value);
    if (value)
      switch (col) {
        case "productCode":
          this.dataListItems[key].productId = options.id;
          this.dataListItems[key].productName = options.nameUse;
          this.dataListItems[key].uom = options.uom;
          this.getDetailProduct(value, key);
          break;
        case "productName":
          this.dataListItems[key].productId = options.id;
          this.dataListItems[key].productCode = options.code;
          this.getDetailProduct(value, key);
          break;
        case "unitCode":
          this.dataListItems[key].unitId = options.id;
          break;
        default:
          break;
      }
    else {
      if (col === "productCode" || col === "productName") {
        this.dataListItems[key].uom = "";
        this.dataListItems[key].productId = "";
        this.dataListItems[key].productCode = "";
        this.dataListItems[key].productName = "";
      }
    }
    this.dataListItems = this.dataListItems.slice();
  }
  onSelectChange(selectedRowKeys) {
    this.selectedRowKeys = selectedRowKeys;
  }
  getDetailProduct(value, key) {
    productService.detailProduct(value).then(res => {
      this.dataListItems[key].uom = res.baseUnit;
    });
  }

  getInitialProductCodeAndName(type) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    const listDropdown = [] as Dropdown[];
    const findColumn = this.columnsTable.find(
      column => column.responsiveColSelect
    )?.responsiveColSelect;
    if (
      type == REQUEST_TYPE.RENT ||
      type == REQUEST_TYPE.UNIT ||
      type == REQUEST_TYPE.SPAREPART ||
      type == REQUEST_TYPE.CAR
    ) {
      params.search = `type~Stockable_OR_type~Non Stockable`;
    } else if (type == REQUEST_TYPE.SERVICE) {
      params.search = `type~Service`;
    } else {
      params.search = `type~General`;
    }
    productService.listProduct(params).then(res => {
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["nameUse"] = item.name;
        item["name"] = item.code;
        listDropdown.push(item);
      });
      findColumn[0].options = listDropdown;
      findColumn[1].options = listDropdown;
    });
  }

  getProductCode(value, type, search) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    const listDropdown = [] as Dropdown[];
    const findColumn = this.columnsTable.find(
      column => column.responsiveColSelect
    )?.responsiveColSelect;
    if (!search) {
      if (
        type == REQUEST_TYPE.RENT ||
        type == REQUEST_TYPE.UNIT ||
        type == REQUEST_TYPE.SPAREPART ||
        type == REQUEST_TYPE.CAR
      ) {
        params.search = `type~Stockable_OR_type~Non Stockable`;
      } else if (type == REQUEST_TYPE.SERVICE) {
        params.search = `type~Service`;
      } else {
        params.search = `type~General`;
      }
    } else {
      if (
        type == REQUEST_TYPE.RENT ||
        type == REQUEST_TYPE.UNIT ||
        type == REQUEST_TYPE.SPAREPART ||
        type == REQUEST_TYPE.CAR
      ) {
        if (value)
          params.search = `description~*${value}*_OR_code~*${value}*_AND_type~Stockable_OR_type~Non Stockable`;
      } else if (type === REQUEST_TYPE.SERVICE) {
        if (value)
          params.search = `description~*${value}*_OR_code~*${value}*_AND_type~Service`;
      } else {
        if (value)
          params.search = `description~*${value}*_OR_code~*${value}*_AND_type~General`;
      }
    }
    productService.listProduct(params).then(res => {
      res.data.forEach((item, index) => {
        item["key"] = index;
        item["nameUse"] = item.name;
        item["name"] = item.code;
        listDropdown.push(item);
      });
      findColumn[0].options = listDropdown;
    });
  }
  getProductName(value, type, search) {
    const params: RequestQueryParamsModel = {
      page: 0,
      limit: 10,
    };
    const listDropdown = [] as Dropdown[];
    const findColumn = this.columnsTable.find(
      column => column.responsiveColSelect
    )?.responsiveColSelect;
    if (!search) {
      if (
        type == REQUEST_TYPE.RENT ||
        type == REQUEST_TYPE.UNIT ||
        type == REQUEST_TYPE.SPAREPART ||
        type == REQUEST_TYPE.CAR
      ) {
        params.search = `type~Stockable_OR_type~Non Stockable`;
      } else if (type == REQUEST_TYPE.SERVICE) {
        params.search = `type~Service`;
      } else {
        params.search = `type~General`;
      }
    } else {
      if (
        type == REQUEST_TYPE.RENT ||
        type == REQUEST_TYPE.UNIT ||
        type == REQUEST_TYPE.SPAREPART ||
        type == REQUEST_TYPE.CAR
      ) {
        if (value)
          params.search = `description~*${value}*_OR_name~*${value}*_AND_type~Stockable_OR_type~Non Stockable`;
      } else if (type == REQUEST_TYPE.SERVICE) {
        if (value)
          params.search = `description~*${value}*_OR_name~*${value}*_AND_type~Service`;
      } else {
        if (value)
          params.search = `description~*${value}*_OR_name~*${value}*_AND_type~General`;
      }
    }
    productService.listProduct(params).then(res => {
      res.data.forEach((item, index) => {
        item["key"] = index;
        listDropdown.push(item);
      });
      findColumn[1].options = listDropdown;
    });
  }
  showConfirmation() {
    if (this.selectedRowKeys.length > 0) {
      this.$confirm({
        title: `Do you want to delete these items?`,
        content: `Total: ${this.selectedRowKeys.length} items will be deleted.`,
        onOk: () => {
          this.dataListItems = this.dataListItems.filter((data, _index) => {
            if (this.selectedRowKeys.includes(data.key)) {
              if (data.id) this.listDeletedId.push(data.id as string);
            }
            return !this.selectedRowKeys.includes(data.key);
          });
          this.dataListItems.forEach((data, index) => (data.key = index));
          this.dataListItems = this.dataListItems.slice();
          this.dataListItems.forEach((item, index) => {
            item.no = index + 1 + ".";
          });
          this.selectedRowKeys = [];
        },
        onCancel() {
          return;
        },
      });
    } else {
      this.$notification.error({
        message: "Error",
        description: "Select at least one row to delete",
      });
    }
  }

  handleAddRow() {
    this.dataListItems = [
      ...this.dataListItems,
      {
        productCode: "",
        productName: "",
        productId: "",
        uom: "",
        key: this.dataListItems.length,
        description: this.form.getFieldValue("description"),
        qty: "",
        id: null,
        unitCode: "",
        unitId: "",
        no: this.dataListItems.length + 1 + ".",
      },
    ];
  }
  filterOption(input, option) {
    return (
      option.componentOptions.children[0].componentOptions.children[1].text
        .toLowerCase()
        .indexOf(input.toLowerCase()) >= 0
    );
  }
}
